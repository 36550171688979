<script setup>
import { defineProps, getCurrentInstance } from 'vue'
import {useMainStore} from "@/store";

const props = defineProps({
  content: {
    type: Object
  },
  btnTitle: {
    type: String,
    default: 'Позвонить сейчас'
  }
})

let store = useMainStore()

const handleSelect = async () => {
  store.selectedShopPhone = localStorage.getItem('shopPhone') || '';
};

const instance = getCurrentInstance();
instance.appContext.config.globalProperties.emitter.on('select', handleSelect);


</script>

<template>
  <section class="action section">
    <div class="container">
      <div class="action__body" :style="`background: url(${require('../assets/images/bg/9.jpg')}) no-repeat center center / cover`">
        <div class="section-head">
          <span class="h2 text--uppercase text--white" v-html="props.content.title"></span>
        </div>
        <div v-html="props.content.text"></div>
        <div class="btn-container">
          <a :href="`tel:${store.selectedShopPhone}`" class="btn">{{ btnTitle }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
