<template>
  <div style="display:none">{{ marketTotal }}</div>
</template>

<script setup>
import {ref, onMounted, computed, getCurrentInstance} from 'vue';
import gql from "graphql-tag";
import { useQuery } from "@vue/apollo-composable";
import { useMainStore } from "@/store";
import Storage from "@/store/storage";

const GET_COORDS_BY_IP = gql`
    query getCoords {
        getCoords {
            lat
            lon
        }
    }
`
const MARKETS = gql`
    query markets {
        markets {
            id
            remote_market_id
            title
            address
            city
            coords
            phone
            vkontakte
            telegram
        }
    }
`

const userCoords = ref(null);
const store = useMainStore()
const { refetch } = useQuery(GET_COORDS_BY_IP, null, { lazy: true });
const {result: marketsResult} = useQuery(MARKETS)
const instance = getCurrentInstance();

function getUserLocation() {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
        position => {
          userCoords.value = {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          };
          localStorage.setItem('lat', userCoords.value.lat)
          localStorage.setItem('lon', userCoords.value.lon)
          store.setLatLon(userCoords.value.lat, userCoords.value.lon )
        },
        error => {
          console.log('ERROR', error)
          // Если ошибка связана с отказом пользователя предоставить разрешение
          //if (error.code === error.PERMISSION_DENIED) {
            getCoordsByIP()
          //}
        }
    )
  } else {
    getCoordsByIP()
  }
}

function getCoordsByIP() {
  refetch().then(({ data }) => {
    userCoords.value = data.getCoords;
    // localStorage.setItem('lat', userCoords.value?.lat)
    // localStorage.setItem('lon', userCoords.value?.lon)
    store.setLatLon(userCoords.value?.lat, userCoords.value?.lon)
  }).catch(error => {
    console.error("Ошибка при выполнении запроса:", error);
  });
}
function getHaversineDistance(lat1, lon1, lat2, lon2) {
  const toRad = (degree) => degree * Math.PI / 180;
  const R = 6371; // радиус Земли в километрах
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  const a = Math.pow(Math.sin(dLat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) *
      Math.pow(Math.sin(dLon / 2), 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}
async function selectShop(shopCoords, shopTitle, shopId, shopCity, shopAddress, shopPhone) {
  await Storage.save('shopCoords', shopCoords);
  await Storage.save('shopTitle', shopTitle);
  await Storage.save('user-shopId', shopId);
  await Storage.save('shopCity', shopCity);
  await Storage.save('shopAddress', shopAddress);
  await Storage.save('shopPhone', shopPhone);

  instance.appContext.config.globalProperties.emitter.emit('select');
}

async function getClosestStore(userLat, userLon, stores) {
  if (typeof userLat === 'undefined' || typeof userLon === 'undefined') {
    return;
  }

  // const selectedShopId = localStorage.getItem('user-shopId');
  const selectedShopId = await Storage.load('user-shopId');
  if(selectedShopId) {
    return;
  }

  let closestStore = stores.reduce((closest, store) => {
    const [storeLat, storeLon] = store.coords.split(',').map(Number);
    const distance = getHaversineDistance(userLat, userLon, storeLat, storeLon);
    return (closest === null || distance < closest.distance)
        ? { store, distance }
        : closest;
  }, null);

  await Storage.save('currentCityId', closestStore.store.remote_market_id)
  await selectShop(closestStore.store.coords, closestStore.store.title, closestStore.store.id, closestStore.store.city, closestStore.store.address, closestStore.store.phone)

  return closestStore.store
}

const marketTotal = computed(() => {
  if(marketsResult?.value?.markets) {
    getClosestStore(userCoords.value?.lat, userCoords.value?.lon, marketsResult?.value?.markets)
  }
  return marketsResult?.value?.markets
})
// watch(store.selectedShopCoords, (newVal) => {
//   if (newVal) {
//     const coords = []
//     userCoords.value = {
//       lat: coords[0],
//       lon: coords[1]
//     };
//     // localStorage.setItem('lat', userCoords.value?.lat);
//     // localStorage.setItem('lon', userCoords.value?.lon);
//     store.setLatLon(userCoords.value?.lat, userCoords.value?.lon)
//   }
// }, { immediate: true });

onMounted(getUserLocation)
</script>
