<script setup>
import gql from 'graphql-tag'
import {useMutation, useQuery} from "@vue/apollo-composable";
import {computed, ref, watch} from "vue";
import ChooseCity from "@/components/ChooseCity.vue";
import {useMainStore} from "@/store";

import {useRoute, useRouter} from 'vue-router';

import PopupWindow from "@/components/PopupWindow.vue"
import SearchInput from "@/components/SearchInput.vue";
import StatisticBlock from "@/components/StatisticBlock.vue";

const MAIN_MENU = gql `
    query commonData {
    commonData{
            mainMenu
        }
    }
`
const MARKETS = gql`
    query markets {
        markets {
            id
            remote_market_id
            title
            address
            city
            coords
            phone
            vkontakte
            telegram
        }
    }
`
const LOGIN = gql`
    mutation login($committent: String!, $phone: String!, $shop_id: String!) {
        login(committent: $committent, phone: $phone, shop_id: $shop_id)
    }
`
const store = useMainStore()

const modalVisible = ref(false);
const loginVisible = ref(false);
const statisticVisible = ref(false);
const phone = ref('')
const contract = ref('')
const shopId = ref('')
let loginData = ref('')
let showCities = ref(false)
const mainMenuMarketPagesResult = ref([]);
let activeShopData

const { result, refetch: refetchMenuData } = useQuery(MAIN_MENU, {}, { fetchPolicy: 'network-only' })
const {result: marketsResult} = useQuery(MARKETS)
const { mutate } = useMutation(LOGIN)

const mainMenuResult = computed(() => {
  return result.value?.commonData?.mainMenu ? JSON.parse(result.value?.commonData?.mainMenu) : {}
})


const updateMenuData = async () => {
  await refetchMenuData(); // Перезапрашиваем данные меню

  const menuData = mainMenuResult.value;
  if (menuData && menuData.markets_pages) {
    const marketPagesArrays = Object.values(menuData.markets_pages);
    mainMenuMarketPagesResult.value = marketPagesArrays
        .flatMap(pages => pages.filter(page => {
          return Number(page.market_id) === Number(localStorage.getItem('user-shopId'));
        }));
  }
};

const marketTotal = computed(() => {
  return marketsResult?.value?.markets
})

const submitForm = async () => {
  if (shopId.value) {
    activeShopData = marketTotal.value.find(item => {
      return item.remote_market_id === shopId.value
    })
  }
  try {
    const { data } = await mutate({
      committent: contract.value,
      phone: phone.value,
      shop_id: shopId.value,
    });
    loginData.value = data?.login
    if (data?.login) {
      await store.selectShop(activeShopData.coords, activeShopData.title, shopId.value, activeShopData.city, activeShopData.address, activeShopData.phone)
      loginVisible.value = false;
      await store.setToken(data.login)
      await store.init()
      window.location.reload()
    }
  } catch (error) {
    loginData.value = undefined
    console.error(error, 'error in submitForm for login')
  }
};
const hasToken = computed(() => !!store.token)
const openModal = async () => {
  await updateMenuData();
  modalVisible.value = true;
};
const openLoginModal = () => {
  modalVisible.value = false;
  loginVisible.value = true;
};
const openStatisticModal = async () => {
  modalVisible.value = false;
  statisticVisible.value = true;
};

const closeModal = () => {
  modalVisible.value = false;
  loginVisible.value = false
  statisticVisible.value = false;
};

const logout = () => {
  modalVisible.value = false;
  store.clearToken()
}

const route = useRoute();

watch(
    () => route.path,
    () => {
      closeModal()
    }
)

 watch( () => store.selectedShopId,
    async () => {
      await updateMenuData()
    }
)

const router = useRouter();

const getCurrentTypeAndSlug = (typeId, selectedShopId) => {
  const pages = mainMenuResult.value.markets_pages?.[selectedShopId];
  if (!pages) return null;

  const page = pages.find(page => page.page_type_id === typeId);
  return page ? page.slug : null;
}

watch(() => route.query.type, async (newType) => {
  const typeId = newType;
  if (typeId) {
    const newSlug = getCurrentTypeAndSlug(typeId, store.selectedShopId);
    if (newSlug) {
      await router.push({ path: `/page/${newSlug}`, query: { type: typeId } });
    }
  }
});

watch(() => store.selectedShopId, async (newVal, oldVal) => {
  if (newVal !== oldVal) {
    await updateMenuData();
    const currentType = route.query.type;
    const newSlug = getCurrentTypeAndSlug(parseInt(currentType), newVal);
    if (newSlug) {
      await router.push({ path: `/page/${newSlug}`, query: { type: currentType } });
    }
  }
});

</script>

<template>
  <header class="header">
    <div class="container">
      <div class="header__wrapper flex align-center justify-between">
        <router-link to="/">
          <img src="@/assets/images/logo.svg" alt="">
        </router-link>
        <div class="header__item flex align-center">
          <div class="icon location">
            <img src="@/assets/images/icons/location.svg" alt="" @click="showCities = !showCities">
            <ChooseCity  :visible="showCities" @close="showCities = false" />
          </div>
          <div class="menu-burger" @click="openModal">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <PopupWindow :visible="modalVisible" @closeModal="closeModal">
      <div class="search-wrapper position-relative">
        <SearchInput />
      </div>
      <ul class="menu" v-if="mainMenuResult">
        <!--    class="current"    -->
        <li v-for="item in mainMenuResult" :key="item.id">
          <router-link :to="`/${item.slug}`" exact-active-class="current">
            {{ item.title }}
          </router-link>
        </li>
        <li v-for="item in mainMenuMarketPagesResult" :key="item.id">
          <router-link :to="`/page/${item.slug}`" exact-active-class="current">
            {{ item.title }}
          </router-link>
        </li>
        <li v-if="hasToken">
          <a href="#" @click.prevent="openStatisticModal" class="">
            Мои продажи
          </a>
        </li>
        <li v-if="!hasToken"><a href="#" @click.prevent="openLoginModal" class="enter flex align-center js--enter">
          <span class="icon"><img src="@/assets/images/icons/exit.svg" alt=""></span>Вход</a>
        </li>
        <li v-if="hasToken"><a href="#" @click.prevent="logout" class="enter flex align-center js--enter">
          <span class="icon" style="transform: rotate(180deg); position:relative; top: -.1em"><img src="@/assets/images/icons/exit.svg" alt=""></span>Выход</a>
        </li>
      </ul>
    </PopupWindow>
    <!--  Для логина  -->
    <PopupWindow :visible="loginVisible" @closeModal="closeModal">
      <div class="popup-form-content popup-form-enter flex justify-center show">
        <div class="popup-form">
          <span class="h2 section-title text--white text--uppercase">Вход в личный <br>кабинет</span>
          <form @submit.prevent="submitForm">
            <div class="input-wrapper">
              <div class="input-icon input-left-icon">
                <img src="@/assets/images/icons/input-icon-2.svg" alt="">
              </div>
              <input v-model="phone" type="tel" placeholder="Ваш номер телефона без +7 или 8">
            </div>
            <div class="input-wrapper">
              <input v-model="contract" type="text" placeholder="Ваш номер комитента">
            </div>
            <div class="input-wrapper">
              <select v-if="marketsResult" v-model="shopId">
                <option value="" selected disabled>Выберите магазин</option>
                <option v-for="item in marketTotal" :key="item.remote_market_id" :value="item.remote_market_id">{{ item.title }}</option>
              </select>
            </div>
            <p class="text--error" v-if="typeof loginData === 'undefined'">Ошибка. Клиент не найден в базе</p>
            <button @click="submitForm" class="btn btn--white" type="submit">Войти</button>
          </form>
        </div>
      </div>
    </PopupWindow>
    <!--  Для статистики  -->
    <StatisticBlock
        :visible="statisticVisible"
        @closeModal="closeModal"
    />
  </header>
</template>

<style scoped>

</style>
