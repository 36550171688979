<script setup>
import {computed, getCurrentInstance, onMounted, onUnmounted, ref} from 'vue'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import ChooseCity from "@/components/ChooseCity.vue";
// import mainCity from "@/composable/mainCity";
// import {useMainStore} from "@/store";
import Storage from "@/store/storage";

const MARKETS = gql`
    query markets {
        markets {
            address
            city
            phone
            worktime_begin
            worktime_end
            title
            map
            id
            coords
            vkontakte
            telegram
        }
    }
`
const {result: marketsResult} = useQuery(MARKETS)
const userShopCity = ref(Storage.load('shopCity'))
const instance = getCurrentInstance();

// const mainStore = useMainStore()

const filteredMarkets = computed(() => {
  if (!marketTotal.value) {
    return []
  }
  return marketTotal.value?.filter(market => {
    return market.title === userShopCity.value
  });
});

const marketTotal = computed(() => {
  return marketsResult?.value?.markets
})

async function updateMainStore() {
  userShopCity.value = await Storage.load('shopTitle');
}
const handleSelect = async () => {
  await updateMainStore()
};
instance.appContext.config.globalProperties.emitter.on('select', handleSelect);
onMounted(async () => {
  userShopCity.value = await Storage.load('shopTitle');
})
onUnmounted(() => {
  instance.appContext.config.globalProperties.emitter.off('select', handleSelect);
})
let showCities = ref(false)
</script>

<template>
  <section class="section">
    <div class="container">
      <div class="contact__wrapper grid col-3 gap-3 sm-col-1 sm-hide">
        <div class="item">
          <div class="contact-info" v-if="marketsResult">
            <div class="section-head">
              <span v-if="userShopCity" class="h2 text--uppercase text--colored cursor-pointer" @click="showCities = !showCities">Магазин в г.{{ userShopCity }}</span>
              <ChooseCity  :visible="showCities" @close="showCities = false" />
            </div>
            <div class="contact-info-block" v-for="item in filteredMarkets" :key="item.id">
              <span class="contact-info__subtitle text--uppercase text--colored">{{ item.title }}</span>
              <div class="contact-item flex">
                <span class="icon contact-icon"><img src="@/assets/images/icons/location.svg" alt=""></span>
                {{ item.address }}
              </div>
              <a href="tel:+78122427742" class="contact-item flex">
                <span class="icon contact-icon"><img src="@/assets/images/icons/phone.svg" alt=""></span>
                {{ item.phone }}
              </a>
              <div class="contact-item flex">
                <span class="icon contact-icon"><img src="@/assets/images/icons/clock.svg" alt=""></span>
                ПН-ВС: с {{ item.worktime_begin }} до {{ item.worktime_end }}
              </div>
             <ul class="social flex align-center">
                <li><a :href="item.vkontakte" target="_blank"><img src="@/assets/images/icons/vk.svg" alt=""></a></li>
                <li><a :href="item.telegram" target="_blank"><img src="@/assets/images/icons/telegram.svg" alt=""></a></li>
             </ul>
            </div>
          </div>
        </div>
        <div class="item grid-col-2">
          <div class="map-wrapper">
            <img :src="filteredMarkets.length > 0 ? filteredMarkets[0].map : require('@/assets/images/map.jpg')" alt="">
          </div>
        </div>
      </div>
      <div class="contact__wrapper-mobile sm-show">
        <div class="section-head">
          <span class="h2 text--uppercase text--colored">Контакты</span>
        </div>
        <div class="contact-info-row flex">
          <div class="contact-info-mobile" v-for="item in filteredMarkets" :key="item.id">
            <div class="contact-info-block">
              <span class="contact-info__subtitle text--uppercase text--colored">{{ item.title }}</span>
              <div class="contact-item flex">
                <span class="icon contact-icon"><img src="@/assets/images/icons/location.svg" alt=""></span>
                {{ item.address }}
              </div>
              <a href="tel:+78122427742" class="contact-item flex">
                <span class="icon contact-icon"><img src="@/assets/images/icons/phone.svg" alt=""></span>
                {{ item.phone }}
              </a>
              <div class="contact-item flex">
                <span class="icon contact-icon"><img src="@/assets/images/icons/clock.svg" alt=""></span>
                ПН-ВС: с {{ item.worktime_begin }} до {{ item.worktime_end }}
              </div>
              <ul class="social flex align-center">
                <li><a :href="item.vkontakte" target="_blank"><img src="@/assets/images/icons/vk.svg" alt=""></a></li>
                <li><a :href="item.telegram" target="_blank"><img src="@/assets/images/icons/telegram.svg" alt=""></a></li>
              </ul>
            </div>
            <div class="map-wrapper">
              <img src="@/assets/images/map.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
