<template>
  <section class="section">
    <div class="container">
      <div class="section-head">
        <span class="h2 text--uppercase text--colored" v-html="content.title"></span>
      </div>
      <div class="acc-wrapper">
        <div class="acc-item" v-for="(item, index) in props.items" :key="index">
          <div v-if="item.content.title" @click.prevent="togglePanel(index)" :class="{active: isPanelOpen(index)}" class="acc__head flex align-center justify-between">
            <span class="acc__head__text">{{ item.content.title }}</span>
            <div class="acc-right-icon"></div>
          </div>
          <div class="acc__body" v-if="isPanelOpen(index)" v-html="item.content.html"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps({
  content: {
    type: [Array, Object]
  },
  items: {
    type: [Array, Object]
  }
})

const openPanels = ref([])

const togglePanel = (index) => {
  openPanels.value[index] = !openPanels.value[index]
}

const isPanelOpen = (index) => {
  return openPanels.value[index] || false
}
</script>

<style scoped>

</style>
