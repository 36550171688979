<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/bundle'

import {defineProps, ref, getCurrentInstance, watch} from 'vue'
import gql from "graphql-tag";
import {useQuery} from "@vue/apollo-composable";
import Storage from "@/store/storage";

const props = defineProps({
  content: {
    type: Object
  },
  // reviewsIframe: {
  //   type: String,
  //   default: ''
  // },
  showSlider: {
    type: Boolean,
    default: false
  },
  rerender: {
    type: Number,
    default: 0
  }
})
const REVIEWS_QUERY = gql`
    query markets {
      markets {
        reviews_iframe
        id
      }
    }
  `;
const {result: reviewsResult} = useQuery(REVIEWS_QUERY, null, {fetchPolicy: 'network-only'});

const userShopId = ref(Storage.load('user-shopId'));
const reviewsIframe = ref('');

const handleSelect = async () => {
  userShopId.value = await Storage.load('user-shopId');
  if (reviewsResult?.value?.markets && userShopId.value) {
    const userReview = reviewsResult.value.markets.find(market => {
      return String(market.id) === userShopId.value
    });
    if (userReview) {
      reviewsIframe.value = userReview.reviews_iframe;
    }
  } 
};

const instance = getCurrentInstance();
instance.appContext.config.globalProperties.emitter.on('select', handleSelect);



watch([reviewsResult, userShopId], async () => {
  handleSelect();
}, {
  immediate: true,
});
</script>

<template>
  <section class="section testimonial pb-0" :key="rerender">
    <div class="container">
      <div class="section-head flex justify-between align-center">
        <span class="h2 text--uppercase text--colored" v-html="props.content.title"></span>
        <div v-if="showSlider" class="testimonial-slider-arrows flex">
          <div class="prev-slide">
            <svg viewBox="0 0 41 41" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 0C9.17815 0 0 9.17818 0 20.5C0 31.8219 9.17815 41 20.5 41C31.8218 41 41 31.8219 41 20.5C41 9.17818 31.8219 0 20.5 0ZM1 20.5C1 9.73046 9.73043 0.999999 20.5 1C31.2696 1 40 9.73046 40 20.5C40 31.2696 31.2696 40 20.5 40C9.73043 40 0.999999 31.2696 1 20.5ZM22.1868 16.8536L18.5404 20.5L22.1868 24.1464L21.4797 24.8536L17.4797 20.8536L17.1261 20.5L17.4797 20.1465L21.4797 16.1464L22.1868 16.8536Z" fill="currentColor"/></svg>
          </div>
          <div class="next-slide">
            <svg viewBox="0 0 41 41" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 0C31.8218 0 41 9.17818 41 20.5C41 31.8219 31.8218 41 20.5 41C9.17815 41 0 31.8219 0 20.5C0 9.17818 9.17815 0 20.5 0ZM40 20.5C40 9.73046 31.2696 0.999999 20.5 1C9.73043 1 1 9.73046 1 20.5C1 31.2696 9.73043 40 20.5 40C31.2696 40 40 31.2696 40 20.5ZM18.8132 16.8536L22.4596 20.5L18.8132 24.1464L19.5203 24.8536L23.5203 20.8536L23.8739 20.5L23.5203 20.1465L19.5203 16.1464L18.8132 16.8536Z" fill="currentColor"/></svg>
          </div>
        </div>
      </div>
      <div class="iframe-block">
        <div style="width:100%;max-width: 560px;height:800px;overflow:hidden;position:relative;" v-html="reviewsIframe"></div>
      </div>
      <div v-if="showSlider" class="testimonial-slider">
        <swiper
            :modules=[Navigation]
            :slides-per-view="3"
            :breakpoints="{ 300:{ slidesPerView:1,  spaceBetween: 10 }, 480:{ slidesPerView:2,  spaceBetween: 10 }, 768:{ slidesPerView:3, spaceBetween: 40 } }"
            :loop="false"
            :navigation="{ nextEl: '.next-slide', prevEl: '.prev-slide' }"
        >
          <swiper-slide v-for="(item, index) in props.content" :key="index" class="advantages-slider">
            <div class="advantages__slide">
              <div class="adv-img position-absolute">
                <img src="@/assets/images/1.png" alt="">
              </div>
              <div class="decor-dot"></div>
              <span class="adv__title text--white text--uppercase">{{ item.content }}</span>
              <div v-html="item.content"></div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="slide">
          <div class="testimonial__slide">
            <div class="testimonial__head flex align-center">
              <div class="avatar">
                <img src="@/assets/images/avatar.jpg" alt="">
              </div>
              <span class="user-name">Виктория Солодовникова</span>
            </div>
            <div class="testimonial__body">
              <ul class="raiting flex">
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
              </ul>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi consequatur ea quos iusto dolore illo aliquid laboriosam eligendi asperiores. Ipsa itaque quidem sed labore numquam ratione consequuntur voluptatum, fugit exercitationem!</p>
            </div>
          </div>
        </div>
        <div class="slide">
          <div class="testimonial__slide">
            <div class="testimonial__head flex align-center">
              <div class="avatar">
                <img src="@/assets/images/avatar.jpg" alt="">
              </div>
              <span class="user-name">Раушана Хохлова</span>
            </div>
            <div class="testimonial__body">
              <ul class="raiting flex">
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon unnoted"><svg><use xlink:href="#svg-star"></use></svg></span></li>
              </ul>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi consequatur ea quos iusto dolore illo aliquid laboriosam eligendi asperiores.</p>
            </div>
          </div>
        </div>
        <div class="slide">
          <div class="testimonial__slide">
            <div class="testimonial__head flex align-center">
              <div class="avatar">
                <img src="@/assets/images/avatar.jpg" alt="">
              </div>
              <span class="user-name">Маргарита Л.</span>
            </div>
            <div class="testimonial__body">
              <ul class="raiting flex">
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon unnoted"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon unnoted"><svg><use xlink:href="#svg-star"></use></svg></span></li>
              </ul>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi consequatur ea quos iusto dolore illo aliquid laboriosam eligendi asperiores. Ipsa itaque quidem sed labore numquam ratione consequuntur voluptatum, fugit exercitationem! Excepturi consequatur ea quos iusto dolore</p>
            </div>
          </div>
        </div>
        <div class="slide">
          <div class="testimonial__slide">
            <div class="testimonial__head flex align-center">
              <div class="avatar">
                <img src="@/assets/images/avatar.jpg" alt="">
              </div>
              <span class="user-name">Виктория Солодовникова</span>
            </div>
            <div class="testimonial__body">
              <ul class="raiting flex">
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon"><svg><use xlink:href="#svg-star"></use></svg></span></li>
                <li><span class="icon star-icon unnoted"><svg><use xlink:href="#svg-star"></use></svg></span></li>
              </ul>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi consequatur ea quos iusto dolore illo aliquid laboriosam eligendi asperiores. Ipsa itaque quidem sed labore numquam ratione consequuntur voluptatum, fugit exercitationem!</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showSlider" class="btn-container">
        <a href="#" class="btn">Оставить отзыв</a>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
