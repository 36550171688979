<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle'

import { defineProps, ref } from 'vue'

const props = defineProps({
  content: {
    type: [Array, Object]
  },
  items: {
    type: [Array, Object]
  }
})

let showFullTest = ref(false);

</script>

<template>
  <section class="section">
    <div class="container">
      <div class="section-head flex justify-between align-center">
        <span class="h2 text--uppercase text--colored" v-html="content.title"></span>
        <div class="advantages-slider-arrows flex">
          <div class="prev-slide">
            <svg viewBox="0 0 41 41" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 0C9.17815 0 0 9.17818 0 20.5C0 31.8219 9.17815 41 20.5 41C31.8218 41 41 31.8219 41 20.5C41 9.17818 31.8219 0 20.5 0ZM1 20.5C1 9.73046 9.73043 0.999999 20.5 1C31.2696 1 40 9.73046 40 20.5C40 31.2696 31.2696 40 20.5 40C9.73043 40 0.999999 31.2696 1 20.5ZM22.1868 16.8536L18.5404 20.5L22.1868 24.1464L21.4797 24.8536L17.4797 20.8536L17.1261 20.5L17.4797 20.1465L21.4797 16.1464L22.1868 16.8536Z" fill="currentColor"/></svg>
          </div>
          <div class="next-slide">
            <svg viewBox="0 0 41 41" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 0C31.8218 0 41 9.17818 41 20.5C41 31.8219 31.8218 41 20.5 41C9.17815 41 0 31.8219 0 20.5C0 9.17818 9.17815 0 20.5 0ZM40 20.5C40 9.73046 31.2696 0.999999 20.5 1C9.73043 1 1 9.73046 1 20.5C1 31.2696 9.73043 40 20.5 40C31.2696 40 40 31.2696 40 20.5ZM18.8132 16.8536L22.4596 20.5L18.8132 24.1464L19.5203 24.8536L23.5203 20.8536L23.8739 20.5L23.5203 20.1465L19.5203 16.1464L18.8132 16.8536Z" fill="currentColor"/></svg>
          </div>
        </div>
      </div>
      <swiper
          :modules=[Navigation]
          :breakpoints="{ 300:{ slidesPerView:1,  spaceBetween: 10 }, 480:{ slidesPerView:2,  spaceBetween: 10 }, 768:{ slidesPerView:3, spaceBetween: 40 } }"
          :loop="false"
          :navigation="{ nextEl: '.next-slide', prevEl: '.prev-slide' }"
        >
        <swiper-slide v-for="(item, index) in props.items" :key="index" :class="{'advantages-slider':true,'show-full-text':showFullTest}">
          <div class="advantages__slide" @click="showFullTest = !showFullTest">
            <div class="adv-img position-absolute">
              <img :src="item.content.icon ? require(`@/assets/images/sliders_bg_icons/${item.content.icon}`) : require(`@/assets/images/sliders_bg_icons/shirt.svg`)" alt="">
            </div>
            <div class="decor-dot"></div>
            <span class="adv__title text--white text--uppercase">{{ item.content.title }}</span>
            <div v-html="item.content.html"></div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<style scoped>
</style>
