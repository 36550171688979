<template>
  <layout>
    <router-view />
  </layout>
</template>

<script setup>
import layout from './layout/MainLayout.vue'
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {useMutation} from "@vue/apollo-composable";
import gql from "graphql-tag";
import {onMounted} from "vue";
import {useMainStore} from "@/store";

const firebaseConfig = {
  apiKey: "AIzaSyAEoiFcdBFEs8EbFxoS5DjE5uJGkpMYdjQ",
  authDomain: "dobro-61a27.firebaseapp.com",
  projectId: "dobro-61a27",
  storageBucket: "dobro-61a27.appspot.com",
  messagingSenderId: "496738507745",
  appId: "1:496738507745:web:05b131a11c36df041610a0",
  measurementId: "G-M3FTGHRKX3"
};

const FCM_TOKEN = gql`
    mutation setFcmToken($token: String!) {
        setFcmToken(token: $token)
    }
`
const { mutate } = useMutation(FCM_TOKEN)
const store = useMainStore()

// Initialize Firebase
initializeApp(firebaseConfig);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging();
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});
onMounted(() => {
  if (store.token) {
    getToken(messaging, {vapidKey: 'BI6B2NEzM26wDEJLY0jOOt5GiUWujdl1-Utp0JNJ6aS6RnKVIsDbk1i4lpFoOodBpQPyNPaAWm0P8tDpG-H_XMk'}).then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        console.log('FCM token ', currentToken)
        mutate({token: currentToken})
        // ...
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });
  }
})
</script>

<style>
</style>
