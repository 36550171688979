<script setup>
import {defineProps, defineEmits, computed, ref, onMounted, watchEffect} from 'vue';
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import PopupWindow from "@/components/PopupWindow.vue";
import Storage from "@/store/storage";
import {useMainStore} from "@/store";
import LoaderElement from "@/components/LoaderElement.vue";

const props = defineProps({
  visible: Boolean,
});

const emit = defineEmits(['closeModal']);
const closeModal = () => {
  emit('closeModal');
};
const formattedUserName = computed(() => {
  if (userName.value) {
    return userName.value.replace(/\s/g, '<br>');
  }
  return '';
});

const store = useMainStore()


let activeTab = ref(1)
const userName = ref("")
const activeContract = ref('')
const contractsArray = ref([])
const savedContracts = ref(false)
const loading = ref(false)

const PRODUCTS_STATISTIC = gql`
  query personalProductsData($contractID: String) {
    personalProductsData(contractID: $contractID) {
      contracts{
        contractID
        contractDate
      }
      productsInStock {
        title
        cost
        count
        status
        update_date
      }
      productsSold {
        title
        count
        cost
        status
        update_date
      }
      summ
    }
  }
`;
const ME = gql`
    query me {
        me {
            name
        }
    }
`
const { result: productsDataResult, variables } = useQuery(PRODUCTS_STATISTIC, {
  contractID: activeContract.value
});
const { onResult: meResult } = useQuery(ME);

const productData = computed(() => {
  return productsDataResult.value?.personalProductsData
});
const getContractData = (contract) => {
  loading.value = true
  activeContract.value = contract
  variables.value = {
    contractID: contract
  }
  loading.value = false
}
watchEffect(async () => {
  if (productsDataResult.value) {
    productData.value = productsDataResult.value.personalProductsData;
  }
  if(productsDataResult.value && !savedContracts.value) {
    await Storage.save('contracts', JSON.stringify(productsDataResult.value?.personalProductsData.contracts))
    contractsArray.value = JSON.parse(await Storage.load('contracts'))
    savedContracts.value = true
  }
});

meResult(({ data }) => {
  userName.value = data?.me?.name;
});

onMounted(() => {
  store.setToken(localStorage.getItem('user-token'));
});

</script>

<template>
  <PopupWindow :visible="props.visible" @closeModal="closeModal">
    <div class="popup__content active" id="sales" v-if="productData && userName && !loading">
      <div class="container">
        <p style="margin-bottom: 1rem; font-size: max(16px, 2.4rem)">
          Выберите ваш номер приложения
        </p>
        <p style="margin-bottom: 3rem; font-size: 14px">
          Проверка продаж онлайн доступна для договоров заключенных с магазином за последние 100 дней
        </p>
        <div class="tabs flex flex-wrap" style="gap: 10px; margin-bottom: 6rem">
          <div v-for="(item, index) in contractsArray" :key="index" @click="getContractData(item.contractID)" class="tab-btn" :class="{active: +activeContract === +item.contractID}" style="margin-left: 0;font-weight: 600;">
            {{ item.contractID }}
            <span style="display: block; font-size: 1.1rem; font-weight: 300;">{{ item.contractDate }}</span>
          </div>
        </div>
        <div class="my-sale-wrapper" v-if="activeContract !== ''">
          <div class="my-sale__head flex justify-between align-center flex-wrap">
            <span class="h2 text--uppercase text--white" v-html="formattedUserName"></span>
            <div class="sale__head__info flex justify-between w-50">
              <div class="w-50">
                <p>Товары в зале:</p>
                <div class="goods text--uppercase text--white">
                  <span class="goods-num">{{ productData.productsInStock.length }} </span>
                  <span v-if="productData.productsInStock.length > 1">
                      {{ productData.productsInStock.length > 1 && productData.productsInStock.length < 4 ? ' товара' : ' товаров' }}
                    </span>
                  <span v-else> товар</span>
                </div>
              </div>
              <div class="w-50">
                <p>Доступно для снятия:</p>
                <span class="h2 text--uppercase text--white total-price">{{ productData.summ }} ₽</span>
              </div>
            </div>
          </div>
          <div class="my-sale__body">
            <span class="h2 text--uppercase text--white">Ваши товары</span>
            <div class="tab-wrapper mt-3">
              <div class="my-sale-order flex align-center justify-between flex-wrap">
                <div></div>
                <div class="tabs contact-mode flex">
                  <span data-id="tab3" class="tab-btn" :class="{active: activeTab === 1 }" @click="activeTab = 1">В магазине</span>
                  <span data-id="tab4" class="tab-btn" :class="{active: activeTab === 2 }" @click="activeTab = 2">Продано</span>
                </div>
              </div>
              <div class="popup-contact__body">
                <div class="tab_container">
                  <div class="tab_content" v-if="activeTab === 1">
                    <div class="goods-list-wrapper scroll-bar">
                      <div class="goods-row flex align-center justify-between" v-for="(item, index) in productData.productsInStock" :key="index">
                        <div class="goods-row-item">
                          <span class="goods-title">{{ item.title }}<span class="sale-date">{{ item.status }}: <span>{{ item.update_date }}</span></span></span>
                        </div>
                        <span class="goods-price text--uppercase text--white">{{ item.cost }} ₽</span>
                      </div>
                    </div>
                  </div>
                  <div class="tab_content" v-if="activeTab === 2">
                    <div class="goods-list-wrapper scroll-bar">
                      <div class="goods-row flex align-center justify-between" v-for="(item, index) in productData.productsSold" :key="index">
                        <div class="goods-row-item">
                          <span class="goods-title">{{ item.title }}<span class="sale-date">{{ item.status }}: <span>{{ item.update_date }}</span></span></span>
                        </div>
                        <span class="goods-price text--uppercase text--white">{{ item.cost }} ₽</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoaderElement v-else />
  </PopupWindow>
</template>

<style scoped>

</style>
