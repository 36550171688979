<template>
  <div>
    <div class="search-wrapper position-relative">
      <input type="search" class="search-input" v-model="searchQuery" @input="search()" placeholder="Найти на сайте...">
      <div class="search-btn position-absolute" @click="search()">
        <span class="icon search-icon"><svg><use xlink:href="#svg-search"></use></svg></span>
      </div>
    </div>
    <ul class="dropdown-menu" v-if="searchQuery.length > 2 && allResults">
      <li v-for="(result, index) in searchResultsPages" :key="index">
        <router-link :to="`/${result.slug}`">
          {{ result.title }}
        </router-link>
      </li>
      <li v-for="(result, index) in searchResultsNews" :key="index">
        <router-link :to="`/news/${result.id}`">
          {{ result.title }}
        </router-link>
      </li>
      <li v-for="(result, index) in searchResultsArticles" :key="index">
        <router-link :to="`/article/${result.id}`">
          {{ result.title }}
        </router-link>
      </li>
    </ul>
    <ul class="dropdown-menu" v-if="searchQuery.length > 2 && (!allResults || false)">
      <li>
        По вашему запросу ничего не найдено!
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, nextTick } from "vue";
import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';

const searchQuery = ref('');
const searchResultsNews = ref([]);
const searchResultsArticles = ref([]);
const searchResultsPages = ref([]);

const SEARCH_QUERY = gql`
  query search($search: String!) {
    search(search: $search) {
      pages {
        id
        title
        slug
      }
      news {
        id
        title
      }
      articles {
        id
        title
      }
    }
  }
`;

const { result } = useQuery(SEARCH_QUERY, { search: searchQuery });

onMounted(() => {
});
watch(result, () => {
  if (result) {
    updateSearchResults(result?.value?.search);
  }
});

const allResults = computed(() =>
    searchResultsNews.value?.length > 0 ||
    searchResultsArticles.value?.length > 0 ||
    searchResultsPages.value?.length > 0
);

const search = () => {
  if (searchQuery.value.length >= 3) {
    updateSearchResults(result?.value?.search);
  } else {
    searchResultsNews.value = [];
    searchResultsArticles.value = [];
    searchResultsPages.value = []
  }
};

const updateSearchResults = async (data) => {
  // searchResults.value = [...data?.search?.pages, ...data?.search?.news, ...data?.search?.articles];
  searchResultsNews.value = data?.news;
  searchResultsArticles.value = data?.articles;
  searchResultsPages.value = data?.pages
  await nextTick(); // ожидает обновления DOM
};
</script>
