import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/homePage.vue'

const routes = [
    {
        name: 'home',
        path: '/',
        alias: '/main',
        component: Home,
    },
    {
        name: 'innerPage',
        path: '/page/:name?',
        component: () => import('../pages/sdatPage.vue')
    },
    {
        name: 'about',
        path: '/about',
        component: () => import('../pages/aboutPage.vue')
    },
    {
        name: 'catalog',
        path: '/catalog',
        component: () => import('../pages/catalogPage.vue')
    },
    {
        name: 'blog',
        path: '/blog',
        component: () => import('../pages/blogPage.vue')
    },
    {
        name: 'contacts',
        path: '/contacts',
        component: () => import('../pages/contactPage.vue')
    },
    // {
    //     name: 'vakansii',
    //     path: '/vakansii',
    //     component: () => import('../pages/vacansiiPage.vue')
    // },
    {
        name: 'requisites',
        path: '/requisites',
        component: () => import('../pages/requisitesPage.vue')
    },
    {
        name: 'dogovor',
        path: '/dogovor',
        component: () => import('../pages/dogovorPage.vue')
    },
    {
        name: 'newsCommon',
        path: '/news/:id',
        component: () => import('../pages/NewsCommon.vue')
    },
    {
        name: 'articleCommon',
        path: '/article/:id',
        component: () => import('../pages/ArticleCommon.vue')
    },
    {
        path: '/:notFound',
        component: () => import('../pages/NotFound.vue')
    },
    {
        path: '/main',
        redirect: '/',
        component: Home,
    },
]

const router = createRouter({
    history: createWebHistory(''),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
})

export default router
