<script setup>
import gql from "graphql-tag";
import { useQuery } from "@vue/apollo-composable";

const PRODUCTS = gql`
    query products {
        products(page: 1, limit: 100 ) {
          price
          title
          id
          categories {
            title
          }
      }
    }
`
const { result } = useQuery(PRODUCTS)
</script>

<template>
  <section class="section" v-if="result">
    <div class="container">
      <div class="catalog-wrapper grid col-3 gap-2 sm-col-2">
        <div v-for="item in result.products" :key="item.id" class="item">
          <div class="product-card">
            <div class="product-img">
                <img decoding="async" loading="lazy" src="@/assets/images/img2.jpg" alt="">
            </div>
            <div class="product-info">
              <span class="product-location">пр. Богатырский д.5/1</span>
              <span class="product-name text--uppercase">{{ item.title }}</span>
              <span class="product-price">{{ item.price }} ₽</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
