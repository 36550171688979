import { createApp, provide, h } from 'vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context';
import App from './App.vue'
import { createPinia } from 'pinia';
import {useMainStore} from "@/store";
import '@/assets/css/style.css'

import mitt from 'mitt';

import router from './router'

const graphQlLink = window.location.hostname === 'localhost' ? 'dobro.satsystems.org' : window.location.hostname
const httpLink = createHttpLink({
    uri: 'https://' + graphQlLink + '/graphql',
});
const authLink = setContext((_, { headers }) => {
    const store = useMainStore()
    // получите аутентификационный токен из хранилища, если он там есть
    const token = store.token;
    // вернуть заголовки контексту, так что httpLink может прочитать их
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(function(registration) {
            console.log('Service Worker Registered', registration);
        })
        .catch(function(err) {
            console.log('Service Worker registration failed: ', err);
        });
}
const cache = new InMemoryCache()

const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink), // Chain it with the HttpLink
    cache,
});

const app = createApp({
    setup () {
        provide(DefaultApolloClient, apolloClient)
    },

    render: () => h(App),
})


const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.use(router)
app.use(createPinia())
app.mount('#app');
