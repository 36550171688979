<script setup>
import {computed, defineProps, ref, onMounted, watch, getCurrentInstance, onUnmounted} from "vue";
import gql from 'graphql-tag'
import {useQuery} from '@vue/apollo-composable'

import FirstScreen from "@/components/FirstScreen.vue";
import AboutBlock from "@/components/AboutBlock.vue";
import PeopleChooseUs from "@/components/PeopleChooseUs.vue";
import FaqBlock from "@/components/FaqBlock.vue";
import FeedBack from "@/components/FeedBack.vue";
import StoresList from "@/components/StoresList.vue";
import CatalogBlock from "@/components/CatalogBlock.vue";
import BeleaveUs from "@/components/BeleaveUs.vue";
import {useMainStore} from "@/store"
import Storage from "@/store/storage";

const props = defineProps({
  slug: {
    type: String,
    default: ''
  },
  firstScreenSimple: {
    type: Boolean,
    default: false
  },
  ctaBtnTitle: {
    type: String,
    default: 'Позвонить сейчас'
  }
})
const instance = getCurrentInstance();
let rerender = ref(1)
const handleSelect = async () => {
  rerender.value += 1
};
instance.appContext.config.globalProperties.emitter.on('select', handleSelect);
// Функция для получения данных по slug
const usePageData = () => {
  const CHARACTERS_QUERY = gql`
    query page($slug: String!){
      page(slug: $slug) {
        blocks
        id
        title
        seo_title
        seo_description
      },
    }
  `;
  const REVIEWS_QUERY = gql`
    query markets {
      markets {
        reviews_iframe
        id
      }
    }
  `;

  const store = useMainStore()

  const {result, loading, refetch} = useQuery(CHARACTERS_QUERY, {slug: props.slug});

  const dynamicBlocks = computed(() => {
    document.title = result?.value?.page?.seo_title || 'Добро'
    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaDescription) {
      metaDescription.setAttribute('content', result?.value?.page?.seo_description || 'Добро! КОМИССИОННЫЙ МАГАЗИН')
    }
    return JSON.parse(result?.value?.page?.blocks);
  });

  const reviewsIframe = ref('');
  const userShopId = ref(Storage.load('user-shopId'));


  const {result: reviewsResult, refetch: refetchReviews} = useQuery(REVIEWS_QUERY, null, {fetchPolicy: 'network-only'});

  watch(() => Storage.load('user-shopId'), (newShopId) => {
    userShopId.value = newShopId;
    refetchReviews(); // Вызываем перезапрос при изменении selectedShopId
  });

  // Перезапрос при изменении slug
  watch(() => props.slug, (newSlug) => {
    refetch({slug: newSlug});
  }, {immediate: true});

  watch([reviewsResult, userShopId], ([newReviews, newShopId]) => {
    if (newReviews && newShopId) {
      const userReview = newReviews.markets.find(market => {
        return String(market.id) === newShopId
      });
      if (userReview) {
        reviewsIframe.value = userReview.reviews_iframe;
      }
    }
  }, {
    immediate: true,
  });

  onMounted(async () => {
    await store.init()
  });
  onUnmounted(() => {
    instance.appContext.config.globalProperties.emitter.off('select', handleSelect);
  })

  const getComponentName = (type) => {
    // Определяем, какому компоненту соответствует тип блока
    switch (type) {
      case 'app-headerex':
        return FirstScreen;
      case 'app-header':
        return FirstScreen;
      case 'app-cta':
        return FeedBack;
      case 'app-text':
        return AboutBlock;
      case 'app-carousel':
        return PeopleChooseUs;
      case 'app-review':
        return BeleaveUs;
      case 'app-accordion':
        return FaqBlock;
      case 'app-markets':
        return StoresList;
      case 'app-products':
        return CatalogBlock;
        // Добавьте другие соответствия здесь
      default:
        return 'div'; // Если тип блока неизвестен, можно использовать обычный div
    }
  }

  const getBlockProps = (block) => {
    // Создаем объект пропсов для каждого блока на основе его типа или содержимого
    switch (block.type) {
      case 'app-header':
        return {content: block.content, simple: props.firstScreenSimple};
      case 'app-headerex':
        return {content: block.content, simple: props.firstScreenSimple};
      case 'app-accordion':
        return {content: block.content, items: block.items};
      case 'app-text':
        return {content: block.content};
      case 'app-cta':
        return {content: block.content, btnTitle: props.ctaBtnTitle};
      case 'app-carousel':
        return {content: block.content, items: block.items};
      case 'app-review':
        // reviewsIframe: reviewsIframe.value
        return {content: block.content, rerender: rerender.value};
      case 'app-markets':
        return {
          content: block.content,
          rerender: rerender.value
        };
      case 'app-products':
        return {
          content: block.content,
        };
      default:
        return {}; // Пустой объект пропсов, если тип блока неизвестен
    }
  };

  return {
    result,
    loading,
    dynamicBlocks,
    getComponentName,
    getBlockProps,
  };
};

// Используйте функцию для получения данных для разных страниц
const {result, dynamicBlocks, getComponentName, getBlockProps} = usePageData('about');
</script>


<template>
  <div style="position:relative;">
    <div v-if="!result" class="loader">
      <img src="@/assets/images/logo.svg" alt="">
      Загружаем....
    </div>
    <div v-if="result">
      <component
          v-for="(block, index) in dynamicBlocks"
          :key="index"
          :is="getComponentName(block.type)"
          :content="block.type"
          v-bind="getBlockProps(block)"
      />
    </div>
  </div>
</template>

<style scoped>

</style>
