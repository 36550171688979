<template>
  <transition name="fade">
    <div class="modal-backdrop" v-if="visibleRef" @click="close">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <button type="button" class="modal-close-btn" @click="close">×</button>
        </div>
        <div class="modal-body">
          <div class="location__dropdown__title">Выберите ближайший магазин из списка</div>
          <div class="location__dropdown__content">
            <div
                class="location__dropdown__item"
                v-for="item in marketTotal"
                :key="item.id"
                @click="selectShop(item.coords, item.title, item.id, item.city, item.address, item.phone)"
            >{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {computed, ref, toRefs, watch, getCurrentInstance} from 'vue';
// import { useMainStore } from '@/store'
import {useQuery} from "@vue/apollo-composable";
import gql from 'graphql-tag'
import Storage from "@/store/storage";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    markets: {
      type: Array,
      default: () => []
    }
  },
  emits: ['close', 'select'],
  setup(props, { emit }) {
    // Convert props to reactive references
    const { visible, markets } = toRefs(props);

    // Create a local reactive state
    const visibleRef = ref(visible.value);
    const marketsRef = ref(markets.value);

    const instance = getCurrentInstance(); 

    // const store = useMainStore()

    const MARKETS = gql`
    query markets {
        markets {
            address
            city
            phone
            worktime_begin
            worktime_end
            title
            map
            id
            coords
            remote_market_id
            vkontakte
            telegram
        }
    }
`
    const {result: marketsResult} = useQuery(MARKETS)

    const marketTotal = computed(() => {
      return marketsResult?.value?.markets
    })

    async function selectShop(shopCoords, shopTitle, shopId, shopCity, shopAddress, shopPhone) {

      // store.selectShop(shopCoords, shopTitle, shopId, shopCity, shopAddress, shopPhone)
      await Storage.save('shopCoords', shopCoords);
      await Storage.save('shopTitle', shopTitle);
      await Storage.save('user-shopId', shopId);
      await Storage.save('shopCity', shopCity);
      await Storage.save('shopAddress', shopAddress);
      await Storage.save('shopPhone', shopPhone);

      instance.appContext.config.globalProperties.emitter.emit('select');

      emit('select')
      // store.setUserShopId(shopId)
      close();
    }

    // Watch for prop changes and update local state
    watch(visible, (newVal) => {
      visibleRef.value = newVal;
    });

    watch(markets, (newVal) => {
      marketsRef.value = newVal;
    });

    function close() {
      emit('close');
    }

    return {
      visibleRef,
      marketsRef,
      close,
      selectShop,
      marketTotal
    };
  }
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 90;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.modal-content {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  z-index: 100;
}
.modal-header {
  position: relative;
  padding: 10px;
}
.modal-close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
  border: none;
  background: none;
}
</style>
