import { defineStore } from 'pinia'
export const useMainStore = defineStore({
    id: 'main',

    state: async () => ({
        token: null,
        selectedShopCoords: '59.938180, 30.365644'.split(',').map(coord => parseFloat(coord.trim())),
        selectedShopTitle: '',
        selectedShopId: '',
        selectedCity: 'Санкт-Петербург',
        selectedShopAddress: '',
        selectedShopPhone: '',
        lat: null,
        lon: null,
    }),

    getters: {
        hasToken: (state) => {
            return !!state.token // возвращает true, если токен не null и не undefined
        },
        getDynamicSlug: (state) => {
            return !!state.dynamicSlug
        }
    },

    actions: {
        async init() {
            this.token = localStorage.getItem('user-token') || null;
            this.selectedShopCoords = localStorage.getItem('shopCoords') ? localStorage.getItem('shopCoords').split(',').map(coord => parseFloat(coord.trim())) : this.selectedShopCoords;
            this.selectedShopTitle = localStorage.getItem('shopTitle') || '';
            this.selectedShopId = localStorage.getItem('user-shopId') || '';
            this.selectedCity = localStorage.getItem('shopCity') || 'Санкт-Петербург';
            this.selectedShopAddress = localStorage.getItem('shopAddress') || '';
            this.selectedShopPhone = localStorage.getItem('shopPhone') || '';
        },
        async selectShop(shopCoords, shopTitle, shopId = localStorage.getItem('user-shopId') || '', shopCity = 'Санкт-Петербург', shopAddress, shopPhone) {
            if (typeof shopCoords === 'string') {
                const coordsArray = shopCoords.split(',').map(coord => parseFloat(coord.trim()));
                if (coordsArray.length === 2) {
                    localStorage.setItem('shopCoords', shopCoords);
                    localStorage.setItem('shopTitle', shopTitle);
                    localStorage.setItem('user-shopId', shopId);
                    localStorage.setItem('shopCity', shopCity);
                    localStorage.setItem('shopAddress', shopAddress);
                    localStorage.setItem('shopPhone', shopPhone);
                    // Обновляем состояние
                    this.selectedShopCoords = coordsArray;
                    this.selectedShopTitle = shopTitle;
                    this.selectedShopId = shopId;
                    this.selectedShopAddress = shopAddress;
                    this.selectedCity = shopCity;
                    this.selectedShopPhone = shopPhone;
                    this.lat = coordsArray[0];
                    this.lon = coordsArray[1];
                } else {
                    console.error('Неверный формат координат');
                }
            } else {
                console.error('shopCoords должен быть строкой');
            }
        },
        setLatLon(lat, lon) {
            this.lat = lat;
            this.lon = lon;
        },
        async setUserShopId(shopId) {
            localStorage.setItem('user-shopId', shopId);
            this.selectedShopId = shopId
        },
        async setToken(newToken) {
            if (!newToken) {
                return false;
            }
            localStorage.setItem('user-token', newToken);
            this.token = newToken;
        },
        async clearToken() {
            this.token = null;
            localStorage.removeItem('user-token')
            localStorage.removeItem('shopCoords');
            localStorage.removeItem('shopTitle');
            localStorage.removeItem('user-shopId');
            localStorage.removeItem('shopCity');
            localStorage.removeItem('shopAddress');
            window.location.reload()
        },
    }
});
