<script setup>
import {useQuery} from "@vue/apollo-composable";
import gql from "graphql-tag";
import {computed, ref} from "vue";
import YandexMap from "@/components/YandexMap.vue";
import GeoLocator from "@/components/GeoLocator.vue";
import StatisticBlock from "@/components/StatisticBlock.vue";
import {useMainStore} from "@/store";

const FOOTER_MENU = gql`
    query commonData {
        commonData {
            footerMenu
            footerRequisites
        }
    }
`
const { result } = useQuery(FOOTER_MENU)
const store = useMainStore()
const getMenu = (index) => {
  return computed(() => {
    const footerMenu = result.value?.commonData?.footerMenu;
    if (footerMenu && footerMenu[index] && typeof footerMenu[index] === 'string') {
      try {
        return JSON.parse(footerMenu[index]);
      } catch (e) {
        console.error('Ошибка разбора JSON в getMenu:', e);
        return {};
      }
    } else {
      return {};
    }
  });
};
const getRequisites = (key) => {
  return computed(() => {
    const footerRequisites = result.value?.commonData?.footerRequisites;
    if (footerRequisites) {
      try {
        const parsedRequisites = JSON.parse(footerRequisites);
        return parsedRequisites[key] || '';
      } catch (e) {
        console.error('Ошибка разбора JSON в getRequisites:', e);
        return `Error parsing JSON for key ${key}`;
      }
    } else {
      return '';
    }
  });
};

const mainMenu = getMenu(0);
const secondMenu = getMenu(1);
const requisitesInn = getRequisites('inn') ;
const requisitesName = getRequisites('title');

const statisticVisible = ref(false);
// const productData = ref(null)
// const hasToken = computed(() => {
//   return !!localStorage.getItem('user-token')
// });


</script>

<template>
  <div>
    <div class="container mt-3">
      <GeoLocator />
      <div style="display: none">
        <YandexMap />
      </div>
    </div>
    <footer class="footer mt-3">
      <div class="container">
        <div class="footer__body bg--main-color">
          <div class="footer-top flex align-center justify-between">
            <div class="footer-top__item">
              <a href="#" class="logo"><img src="@/assets/images/logo_white.svg" alt=""></a>
              <span class="footer-subtitle text--white text--uppercase">Комиссионный магазин</span>
            </div>
            <a href="#" class="on-top__wrapper flex align-center">
              <span>Вернуться <br>наверх</span>
              <div class="on-top-icon page-up">
                <img src="@/assets/images/icons/on_top.svg" alt="">
              </div>
            </a>
          </div>
          <div class="footer-nav-wrapper">
            <ul class="footer-nav flex flex-wrap">
              <li v-for="item in mainMenu" :key="item.id">
                <router-link :to="item.slug">
                  {{ item.title }}
                </router-link>
              </li>
              <li v-if="store.hasToken">
                <a href="#" @click.prevent="() => {statisticVisible = true}" class="">
                  Мои продажи
                </a>
              </li>
            </ul>
            <ul class="footer-sub-nav flex flex-wrap">
              <li v-for="item in secondMenu" :key="item.id">
                <router-link :to="item.slug">
                  {{ item.title }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="footer-bottom flex align-center justify-between">
            <span>ИП {{ requisitesName }}</span>
            <span>ИНН: {{ requisitesInn }}</span>
          </div>
        </div>
      </div>
    </footer>
    <!--  Для статистики  -->
    <StatisticBlock
        :visible="statisticVisible"
        @closeModal="() => {statisticVisible = false}"
    />
    <div class="svg-library">
      <svg xmlns="http://www.w3.org/2000/svg">
        <symbol viewBox="0 0 17 16" fill="none" id="svg-star">
          <path d="M8.5 0.190674L11.3676 4.74371L16.584 6.06403L13.1399 10.1983L13.4962 15.5673L8.5 13.5694L3.50383 15.5673L3.86007 10.1983L0.416019 6.06403L5.63237 4.74371L8.5 0.190674Z" fill="currentColor"/>
        </symbol>

        <symbol viewBox="0 0 12 12" fill="none" id="svg-search">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.17046 0.0255311C3.12865 0.15723 2.14373 0.641445 1.39614 1.38948C-0.018306 2.80478 -0.399941 4.95031 0.444237 6.74114C1.14181 8.22098 2.53298 9.24487 4.13063 9.45438C5.31609 9.60982 6.52993 9.30513 7.49892 8.60892C7.61411 8.52615 7.71661 8.45845 7.72671 8.45845C7.73682 8.45845 8.52067 9.23382 9.46862 10.1815C11.2852 11.9976 11.2872 11.9994 11.497 12C11.6005 12.0003 11.8029 11.9152 11.8649 11.8453C11.9325 11.7691 12 11.5948 12 11.4964C12 11.2869 11.9983 11.2849 10.1829 9.46681C9.23576 8.5183 8.46086 7.73505 8.46086 7.72625C8.46086 7.71748 8.52937 7.61344 8.61311 7.49506C9.19567 6.67149 9.49082 5.75275 9.49201 4.759C9.49356 3.47357 9.00077 2.28453 8.09189 1.38059C7.37617 0.668751 6.49272 0.217566 5.48337 0.0483567C5.16497 -0.00503583 4.50321 -0.0165307 4.17046 0.0255311ZM5.29581 1.0345C6.36494 1.20631 7.27765 1.7846 7.85704 2.65734C8.28801 3.30645 8.48593 3.95558 8.48858 4.72848C8.48984 5.10068 8.46328 5.32622 8.37792 5.66747C8.04788 6.98706 6.99036 8.04522 5.67156 8.37545C5.33051 8.46087 5.10511 8.48745 4.73314 8.48618C3.9607 8.48353 3.31196 8.28549 2.66324 7.85427C1.6279 7.16608 1.00588 5.9997 1.00551 4.74582C1.00532 4.11506 1.13462 3.58655 1.42805 3.01849C1.90218 2.10066 2.74401 1.41744 3.76018 1.12575C4.18863 1.00278 4.85285 0.963302 5.29581 1.0345Z" fill="currentColor"/>
        </symbol>
      </svg>
    </div>
  </div>
</template>

<style scoped>

</style>
