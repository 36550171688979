<script setup>
import {computed, defineProps, onBeforeUnmount, onMounted, ref, watch, getCurrentInstance, onUnmounted} from 'vue'
import {useMainStore} from "@/store";
import ChooseCity from "@/components/ChooseCity.vue";
import PopupWindow from "@/components/PopupWindow.vue";
import YandexMap from "@/components/YandexMap.vue";
import {useQuery} from "@vue/apollo-composable";
import gql from "graphql-tag";
import Storage from "@/store/storage";


const MAIN_MENU = gql `
    query commonData {
    commonData{
            mainMenu
        }
    }
`

const { result, refetch } = useQuery(MAIN_MENU)

const store = useMainStore()
const mainStore = ref('Санкт-Петербург')
const shopAddress = ref('')

const userShopId = ref(Storage.load('user-shopId'));
let rerender = ref(1)

const selectedMarketPage = computed(() => {
  let marketPagesArrays = []
  if (result?.value?.commonData?.mainMenu) {
    const menuData = JSON.parse(result.value.commonData.mainMenu)
    if (menuData && menuData.markets_pages) {
      marketPagesArrays = Object.values(menuData.markets_pages)
      const currentShopId = Number(userShopId.value)
      marketPagesArrays = marketPagesArrays.flatMap(pages => {
            return pages.filter(page => {
                  return Number(page.market_id) === currentShopId && Number(page.page_type_id) === 1
                }
            )
          }
      );
    }
  }
  return marketPagesArrays
})

watch(() => store.selectedShopId, () => {
  // Обновляем linkTo и linkTitle
}, { immediate: true });

const handleSelect = async () => {
  userShopId.value = await Storage.load('user-shopId');
  await updateMainStore()
};

const instance = getCurrentInstance();
instance.appContext.config.globalProperties.emitter.on('select', handleSelect);

onMounted(async () => {
  userShopId.value = await Storage.load('user-shopId') || 1;
  await updateMainStore();
  window.addEventListener('storage', handleStorageChange);
});
onUnmounted(() => {
  instance.appContext.config.globalProperties.emitter.off('select', handleSelect);
})

onBeforeUnmount(() => {
  window.removeEventListener('storage', handleStorageChange);
});

function handleStorageChange(event) {
  if (event.key === 'shopTitle') {
    updateMainStore();
  }
}
async function updateMainStore() {
  const shopTitle = await Storage.load('shopTitle');
  mainStore.value = shopTitle || 'Санкт-Петербург';
  shopAddress.value = await Storage.load('shopAddress');
  await refetch()
}

const linkTo = computed(() => {
  return selectedMarketPage.value ? `/page/${selectedMarketPage.value[0]?.slug}?type=1` : '/'
});

const linkTitle = computed(() => {
  return selectedMarketPage.value ? selectedMarketPage.value[0]?.title : ''
});

const props = defineProps({
  content: {
    type: [Array, Object]
  },
  bgImage: {
    type: String,
    default: ''
  },
  // Обычный первый экран, где только заголовок. Без описания и кнопок
  simple: {
    type: Boolean,
    default: false
  }
})
let showCities = ref(false)
const modalVisible = ref(false)

const closeModal = () => {
  modalVisible.value = false
}

</script>

<template>
  <section class="first-screen section pt-0">
    <div class="container">
      <div class="fs__body" :style="`background: url(${props.content.image ? props.content.image : require('../assets/images/bg/1.jpg')}) no-repeat center center / cover;`">
      <div class="fs-top flex align-center justify-between" :key="rerender">
          <span class="fs-top__title text--white text--uppercase">Комиссионный магазин </span>
          <span class="fs-top__city" @click="showCities = !showCities">{{ mainStore }}</span>
          <ChooseCity  :visible="showCities" @close="showCities = false" @select="updateMainStore" />
        </div>
        <div v-if="simple" class="fs__content">
          <span class="h1 text--white text--uppercase" v-html="props.content.title ? props.content.title : props.content.header_1"></span>
        </div>
        <div v-if="!simple" class="fs__content">
          <span v-if="props.content.title || props.content.header_1" class="h1 text--white text--uppercase" v-html="props.content.title ? props.content.title : props.content.header_1"></span>
          <div v-else class="fs__logo">
            <img src="@/assets/images/logo_big.svg" alt="">
          </div>
          <span class="fs__subtitle text--white text--uppercase" v-html="props.content.header_2"></span>
          <p v-html="props.content.header_3"></p>
        </div>
        <div v-if="!simple" class="fs-bottom flex justify-start">
          <div class="fs__link__wrapper flex align-center">
            <router-link v-if="linkTo && linkTitle" :to="`${linkTo}`" class="fs__link">
              Сдать вещи
            </router-link>
<!--            <div v-if="props.content.right_link_title" @click="modalVisible = true" class="fs__link active">{{ props.content.right_link_title }}</div>-->
            <div @click="modalVisible = true" class="fs__link active">Найти магазин</div>
          </div>
        </div>
      </div>
    </div>
    <PopupWindow :visible="modalVisible" @closeModal="closeModal">
      <div class="search-wrapper position-relative">
        <div class="section-head">
          <div class="h2 text--white text--uppercase">
            Ближайший к Вам магазин
          </div>
          <div class="h2 text--white section-head">
            {{ shopAddress }}
          </div>
        </div>
        <YandexMap />
      </div>
    </PopupWindow>
  </section>
</template>

<style scoped>

</style>
