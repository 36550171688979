<template>
  <div v-if="props.visible" class="popup-window">
    <div class="popup-header position-absolute w-100">
      <div class="container">
        <div class="popup-header-row flex align-center justify-between">
          <a href="#" class="logo"><img src="@/assets/images/logo_white.svg" alt=""></a>
          <span class="close-popup" @click="closeModal"></span>
        </div>
      </div>
    </div>
    <!-- Popup Menu content -->
    <div class="popup__content">
      <div class="container">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, onUnmounted, watch } from 'vue';

const props = defineProps({
  visible: Boolean,
});

const emits = defineEmits(['closeModal']);

const closeModal = () => {
  emits('closeModal');
};

const bodyScrollDisabled = (visible) => {
  if (visible) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = ''
  }
}

onMounted(() => {
  bodyScrollDisabled(props.visible);
});

// Удаление стиля при размонтировании компонента
onUnmounted(() => {
  bodyScrollDisabled(false);
});

// Отслеживаем изменения свойства visible
watch(() => props.visible, (newVal) => {
  bodyScrollDisabled(newVal);
});

</script>
<style>

</style>
