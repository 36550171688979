import { Preferences as StorageCapacitor } from '@capacitor/preferences'
import { Capacitor } from '@capacitor/core'

class Storage {
    static async save(key, value) {
        if (Capacitor.isNativePlatform()) {
            try {
                await StorageCapacitor.set({
                    key,
                    value: JSON.stringify(value),
                })
            } catch (e) {
                console.error('Can\'t get from StorageCapacitor.set', e.message)
            }
        }
        localStorage.setItem(key, value)
    }

    static async load(key) {
        if (Capacitor.isNativePlatform()) {
            try {
                const item = await StorageCapacitor.get({ key })
                await this.save(key, JSON.parse(item.value))
                return JSON.parse(item.value)
            } catch (e) {
                console.error('Can\'t get from StorageCapacitor.get', e.message)
            }
        }
        return localStorage.getItem(key)
    }

    static async remove(key) {
        if (Capacitor.isNativePlatform()) {
            try {
                await StorageCapacitor.remove({ key })
            } catch (e) {
                console.error('Can\'t get from StorageCapacitor.remove', e.message)
            }
        }
        localStorage.removeItem(key)
    }
}

export default Storage
