<script setup>
/* global ymaps */
import { ref, onMounted, watchEffect } from 'vue';
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { useMainStore } from "@/store";
import Storage from "@/store/storage";

const myMap = {};
const MARKETS_COORDS = gql`
  query markets {
    markets {
      coords
      title
      worktime_begin
      worktime_end
      city
      address
      phone
      id
    }
  }
`;

const { result, loading } = useQuery(MARKETS_COORDS);

const store = useMainStore()
let coordsString = ''
let userLat = store.lat
let userLon = store.lon
// const userPosition = [parseFloat(userLat), parseFloat(userLon)]
const userCoords = ref([parseFloat(userLat), parseFloat(userLon)]);

const getDistance = (pos1, pos2) => {
  if (pos1 && pos2) {
    return ymaps?.coordSystem?.geo?.getDistance(pos1, pos2);
  }
  return false
}
let nearestMarket = null;
let shortestDistance = Infinity;

const addMarkers = (marketsData) => {
  if (!myMap.value) return;

  // Очистка существующих маркеров, если они есть
  myMap.value.geoObjects.removeAll();

  marketsData?.forEach((market) => {
    const coords = market.coords.split(',').map(coord => parseFloat(coord.trim()));
    if (isNaN(coords[0]) || isNaN(coords[1])) {
      console.error("Недействительные координаты:", market.coords);
      return; // Пропускаем текущий маркет и переходим к следующему
    }

    const placemark = new ymaps.Placemark(coords, {
      hintContent: market.title,
      balloonContent: 'Время работы: ' + market.worktime_begin + ' - ' + market.worktime_end,
    });
    myMap.value.geoObjects.add(placemark);
  });
};


const initMap = async () => {
  await ymaps.ready(() => {
    let centerCoords = localStorage.getItem('shopCoords') ? localStorage.getItem('shopCoords').split(',').map(coord => parseFloat(coord.trim())) : [59.938180, 30.365644]; // значения по умолчанию
    if (nearestMarket && nearestMarket.coords) {
      const coordsArray = nearestMarket.coords.split(',').map(coord => parseFloat(coord.trim()));
      if (coordsArray.length === 2 && !isNaN(coordsArray[0]) && !isNaN(coordsArray[1])) {
        centerCoords = coordsArray;
      }
    }
    if (result.value && result.value.markets && result.value.markets[0]) {
      const coordsStr = result.value.markets[0].coords;
      const coordsArray = coordsStr.split(',').map(coord => parseFloat(coord.trim()));
      if (coordsArray.length === 2 && !isNaN(coordsArray[0]) && !isNaN(coordsArray[1])) {
        if(localStorage.getItem('shopAddress') === null) {
          localStorage.setItem('shopAddress', result?.value?.markets[0]?.address)
        }
        centerCoords = coordsArray;
      }
    }
    // if (store.selectedShopCoords) {
    //   nextTick(() => {
    //     updateMapCenter(store.selectedShopCoords);
    //   });
    // }
    centerCoords = [userLat, userLon]
    myMap.value = new ymaps.Map("map", {
      center: centerCoords,
      zoom: 13
    });

    if (!loading.value) {
      setTimeout(() => {
        addMarkers(result.value.markets);
      }, 100);
    }
  });
};


onMounted(async () => {
  if (await Storage.load('shopCoords')) {
    coordsString = await Storage.load('shopCoords')
    userLat = coordsString.split(',')[0]
    userLon = coordsString.split(',')[1]
  }
  if (!window.ymaps) {
    console.error("Yandex Maps API не загружен!");
    return;
  }
  ymaps.ready(async() => {
    await initMap();
  });
});


const updateMapCenter = (coords) => {
  if (myMap.value && coords) {
    myMap.value.setCenter(coords);
  }
};
// watchEffect(() => {
//   if (store.selectedShopCoords) {
//     nextTick(() => {
//       updateMapCenter(store.selectedShopCoords);
//     });
//   }
// });
watchEffect(() => {
  if (!loading.value && result.value?.markets) {
    addMarkers(result.value.markets);
  }
});
watchEffect(() => {
  if (result.value && result.value.markets) {
    // Если есть координаты в localStorage, тогда ставим центр карты на этот магазин
    if(localStorage.getItem('shopCoords')) {
      const nearestCoords = localStorage.getItem('shopCoords').split(',').map(coord => parseFloat(coord.trim()));
      updateMapCenter(nearestCoords);
      return
    }
    result.value.markets.forEach((market) => {
      const marketCoords = market.coords.split(',').map(coord => parseFloat(coord.trim()));

      // Проверка на валидность координат магазина
      if (marketCoords.length === 2 && !isNaN(marketCoords[0]) && !isNaN(marketCoords[1])) {
        const distance = getDistance(userCoords.value, marketCoords);

        // Обновление ближайшего магазина и расстояния, если найден ближайший
        if (distance < shortestDistance) {
          shortestDistance = distance;
          nearestMarket = market;
          store.setUserShopId(nearestMarket.id)
          store.selectShop(nearestMarket.coords, nearestMarket.title, nearestMarket.id, nearestMarket.city, nearestMarket.address, nearestMarket.phone)
        }
      }
    });

    // Если ближайший магазин найден, обновляем центр карты
    if (nearestMarket) {
      // const nearestCoords = nearestMarket.coords.split(',').map(coord => parseFloat(coord.trim()));
      // updateMapCenter(nearestCoords);
    }
  }
});

// Следим за изменениями координат пользователя и обновляем карту
watchEffect(() => {
  const newLat = parseFloat(store.lat);
  const newLon = parseFloat(store.lon);
  if (!isNaN(newLat) && !isNaN(newLon)) {
    userCoords.value = [newLat, newLon];
    // updateMapCenter(userCoords.value);
  }
});


</script>

<template>
  <div>
    <div id="map" style="width: 100%; height: 400px;"></div>
  </div>
</template>

<style scoped>
/* Ваши стили (если они есть) */
</style>
